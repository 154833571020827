<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-detail">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/bulk_order' }">订单列表</el-breadcrumb-item>
					<el-breadcrumb-item>订单详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div v-loading="loading">
				<template v-if="orderDetail">
					<div class="order-status">
						<h4>
							订单状态：
							<span class="ns-text-color">{{ orderDetail.is_terminate ? '异常订单' : orderDetail.order_status_name }}</span>
						</h4>
						<div v-if="orderDetail.order_status == 0" class="go-pay">
							<p>
								需付款：
								<span>￥{{ orderDetail.pay_money }}</span>
							</p>
						</div>

						<div class="operation" v-if="orderDetail.order_status == 0 && orderDetail.action && orderDetail.action.length > 0">
							<el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1" @click="operation('memberOrderEvaluation')">
								<template v-if="orderDetail.evaluate_status == 0">
									评价
								</template>
								<template v-else-if="orderDetail.evaluate_status == 1">
									追评
								</template>
							</el-button>

							<el-button type="primary" size="mini" :plain="operationItem.action == 'orderPay' ? false : true" v-for="(operationItem, operationIndex) in orderDetail.action"
							 :key="operationIndex" @click="operation(operationItem.action)">
								{{ operationItem.title }}
							</el-button>
						</div>
						<div class="operation" v-else-if="orderDetail.action && orderDetail.action.length == 0 && orderDetail.is_evaluate == 1">
							<el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1" @click="operation('memberOrderEvaluation')">
								<template v-if="orderDetail.evaluate_status == 0">
									评价
								</template>
								<template v-else-if="orderDetail.evaluate_status == 1">
									追评
								</template>
							</el-button>
						</div>
					</div>
					
					<div class="order-info " v-if="Number(orderDetail.refund_data.bulk_can_refund_money) > 0" >
						<h4>售后维权：
						<el-button type="primary" plain size="mini"  @click="$router.push({ path: '/member/refund', query: { order_goods_id: orderDetail.order_goods_id, order_id: orderDetail.order_id } })">
							退款
						</el-button>
						</h4>
					</div>
					
					
					<div class="order-info">
						<h4>订单信息</h4>
						<ul>
							<li>
								<i class="iconfont iconmendian"></i>
								店铺：
								<router-link :to="'/shop-' + orderDetail.site_id" target="_blank">{{ orderDetail.site_name }}</router-link>
							</li>
							<li>订单类型：{{ orderDetail.order_type_name }}</li>
							<li>订单编号：{{ orderDetail.order_no }}</li>
							<li>订单交易号：{{ orderDetail.out_trade_no }}</li>
							<li>配送方式：{{ orderDetail.delivery_type_name }}</li>
							<li>创建时间：{{ $util.timeStampTurnTime(orderDetail.create_time) }}</li>
							<li v-if="orderDetail.close_time > 0">关闭时间：{{ $util.timeStampTurnTime(orderDetail.close_time) }}</li>
							<template v-if="orderDetail.pay_status > 0">
								<li>支付方式：{{ orderDetail.pay_type_name }}</li>
								<li>支付时间：{{ $util.timeStampTurnTime(orderDetail.pay_time) }}</li>
							</template>
							<li v-if="orderDetail.promotion_type_name != ''">店铺活动：{{ orderDetail.promotion_type_name }}</li>
							<li v-if="orderDetail.buyer_message != ''">买家留言：{{ orderDetail.buyer_message }}</li>
						</ul>
					</div>
					
					
					
					<div class="take-delivery-info">
						<h4>收货信息</h4>
						<ul>
							<li>收货人：{{ orderDetail.name }}</li>
							<li>手机号码：{{ orderDetail.mobile }}</li>
							<li>收货地址：{{ orderDetail.full_address }} {{ orderDetail.address }}</li>
						</ul>
					</div>
					<!-- 发票信息 -->
					<div class="take-delivery-info" v-if="orderDetail.is_invoice ==1">
						<h4>发票信息</h4>
						<ul>
							<li>发票类型：{{ orderDetail.invoice_type == 1 ? '纸质发票' : '电子发票' }}</li>
							<li>发票抬头类型：{{ orderDetail.invoice_title_type ==1 ? '个人' : '企业' }}</li>
							<li>发票抬头：{{ orderDetail.invoice_title }}</li>
							<li>发票内容：{{ orderDetail.invoice_content }}</li>
							<li v-if="orderDetail.invoice_type == 1">发票邮寄地址地址：{{ orderDetail.invoice_full_address }}</li>
							<li v-else>发票接收邮箱：{{ orderDetail.invoice_email }}</li>
						</ul>
					</div>
					<nav>
						<li :class="{ 'no-operation': !orderDetail.is_enable_refund }">商品信息</li>
						<li>单价</li>
						<li>数量</li>
						<li>小计</li>
						<li v-if="orderDetail.is_enable_refund">操作</li>
					</nav>

					<!-- 订单项·商品 -->
					<div class="list">
						<ul class="item" v-for="(goodsItem, goodsIndex) in orderDetail.order_goods" :key="goodsIndex">
							<li :class="{ 'no-operation': !orderDetail.is_enable_refund }">
								<div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
									<img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
								</div>
								<div class="info-wrap">
									<h5 @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">{{ goodsItem.sku_name }}</h5>
									<!-- <span>规格：规格值</span> -->
								</div>
							</li>
							<li>
								<span>￥{{ goodsItem.price }}</span>
							</li>
							<li>
								<span>{{ goodsItem.num }}</span>
							</li>
							<li>
								<span>￥{{ goodsItem.goods_money }}</span>
							</li>
							<!-- <li v-if="orderDetail.is_enable_refund">
								<el-button type="primary" plain size="mini" v-if="goodsItem.refund_status == 0" @click="$router.push({ path: '/member/refund', query: { order_goods_id: goodsItem.order_goods_id, order_id: orderId } })">
									退款
								</el-button>
								<el-button type="primary" plain size="mini" v-else @click="$router.push({ path: '/member/refund_detail', query: { order_goods_id: goodsItem.order_goods_id } })">
									查看退款
								</el-button>
							</li> -->
						</ul>
					</div>
					<div class="take-delivery-info pay_info" v-if="orderDetail.pay_plan && orderDetail.pay_plan.length">
						<h4>付款详情<el-progress :stroke-width="8" :percentage="calcPercent('pay')"></el-progress></h4>
						<el-table :data="orderDetail.pay_plan" border style="width: 100%">
						    <el-table-column prop="periods" label="期数" width="150">
								<template slot-scope="item">
									第{{item.row.periods}}期
								</template>
						    </el-table-column>
						    <el-table-column prop="plan_time" label="合同时间" width="160">
								<template slot-scope="item">
									{{$util.timeStampTurnTime(item.row.plan_time)}}
								</template>
						    </el-table-column>
						    <el-table-column prop="plan_amount" label="金额">
								<template slot-scope="item">
									{{item.row.plan_amount}}
								</template>
						    </el-table-column>
							<el-table-column prop="pay_status_name" label="状态">
								<template slot-scope="item">
									{{item.row.pay_status_name}}
								</template>
							</el-table-column>
							<el-table-column prop="row.pay_time" label="支付时间" width="160">
								<template slot-scope="item">
									{{$util.timeStampTurnTime(item.row.pay_time)}}
								</template>
							</el-table-column>
							<el-table-column prop="payment_voucher" label="支付凭证">
								<template slot-scope="item">
									<img v-if="item.row.payment_voucher" class="payment_voucher" :src="$img(item.row.payment_voucher)" alt="">
								</template>
							</el-table-column>
						  </el-table>
					</div>
					<div class="take-delivery-info pay_info"v-if="orderDetail.shipping_plan && orderDetail.shipping_plan.plam_list.length && orderDetail.shipping_plan.plam_list[0].plan_time">
						<h4>发货详情<el-progress :stroke-width="8" :percentage="calcPercent('shipping')"></el-progress></h4>
						<h5>商品清单</h5>
						<el-table :data="orderDetail.order_goods" border style="width: 100%">
						    <el-table-column prop="item" label="商品" width="350">
								<template slot-scope="item">
									<div class="goods_item">
										<img :src="$img(item.row.sku_image)" alt="">
										<div class="goods_info">
											<div class="name">{{item.row.sku_name}}</div>
										</div>
									</div>
								</template>
						    </el-table-column>
						    <el-table-column prop="price" label="价格(元)" >
						    </el-table-column>
						    <el-table-column prop="num" label="数量">
						    </el-table-column>
							<el-table-column prop="item" label="缺货信息">
								<template slot-scope="item">
									<div class="goods_item" :class="item.row.shortage_num > 0 ? 'red-text' : ''">
										缺货数：{{item.row.shortage_num}}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="goods_money" label="小计" >
							</el-table-column>
							<el-table-column prop="periods" label="发货进程" width="180">
								<template slot-scope="item">
									<el-progress :stroke-width="8" :percentage="calcGoodsPercent(item.row.delivery_num,item.row.num)"></el-progress>
								</template>
							</el-table-column>
						  </el-table>
						<h5 >发货计划</h5>
						<el-table :data="orderDetail.shipping_plan.plam_list" border style="width: 100%">
						    <el-table-column prop="periods" label="期数" width="230">
								<template slot-scope="item">
									第{{item.row.periods}}期
								</template>
						    </el-table-column>
						    <el-table-column prop="plan_time" label="合同时间" width="230">
						    </el-table-column>
						    <el-table-column prop="goods_list" label="合同商品">
								<template slot-scope="item">
									<div class="goods_item" v-for="(goodItem,goodIndex) in item.row.goods_list" :key="goodIndex">
										<img :src="$img(goodItem.sku_image)" alt="">
										<div class="goods_info">
											<div class="name">{{goodItem.sku_name}}</div>
											<div class="num">已发货：{{goodItem.delivery_num}}</div>
										</div>
									</div>
								</template>
						    </el-table-column>
						  </el-table>
					</div>
					<div class="take-delivery-info pay_info" v-if="orderDetail.package_list.length">
						<h4>物流详情</h4>
						<div class="package_names">
							<div class="name" @click="show_package = index" :class="index==show_package ? 'active' : ''" v-for="(item,index) in orderDetail.package_list" :key="index">{{item.package_name}}</div>
						</div>
						<div class="package_info">
							<div class="left_info">
								<div class="item">
									<div class="tit">发货时间：</div>
									{{$util.timeStampTurnTime(orderDetail.package_list[show_package].delivery_time)}}
								</div>
								<div class="item">
									<div class="tit">物流公司：</div>
									{{orderDetail.package_list[show_package].express_company_name}}
								</div>
								<div class="item">
									<div class="tit">运单号：</div>
									{{orderDetail.package_list[show_package].delivery_no}}
								</div>
								<div class="item">
									<div class="tit">期数：</div>
									{{orderDetail.package_list[show_package].periods}}
								</div>
								<div class="item">
									<div class="tit">发货仓：</div>
									{{orderDetail.package_list[show_package].warehouse_name}}
								</div>
								<div class="goods_info" v-for="(goodItem,goodIndex) in orderDetail.package_list[show_package].goods_list" :key="goodIndex">
									<img :src="$img(goodItem.sku_image)" alt="">
									<div class="info">
										<div class="name">{{goodItem.sku_name}}</div>
										<div class="num">数量：{{goodItem.num}}</div>
									</div>
								</div>
							</div>
							<div class="right_info">
								<div class="package_status" v-if="orderDetail.package_list[show_package].trace.success">物流状态：{{orderDetail.package_list[show_package].trace.status_name}}</div>
								<div class="package_status" v-else>物流状态：{{orderDetail.package_list[show_package].trace.reason}}</div>
								<el-timeline>
									<el-timeline-item v-if="orderDetail.package_list[show_package].trace.success" v-for="(item, index) in orderDetail.package_list[show_package].trace.list" :key="index"  :timestamp="item.datetime" placement="top" color="#00a98f">
										{{item.remark}}
									</el-timeline-item>
								</el-timeline>
							</div>
						</div>
					</div>
					
					<div class="take-delivery-info">
						<h4>结算信息</h4>
						<ul>
							<li>商品总额：￥{{ orderDetail.goods_money }}</li>
							<li>运费：￥{{ orderDetail.delivery_money }}</li>
							<li v-if="orderDetail.adjust_money != 0">
								<label>订单调整：</label>
								<span>
									<template v-if="orderDetail.adjust_money < 0">
										-
									</template>
									<template v-else>
										+
									</template>
									￥{{ orderDetail.adjust_money | abs }}
								</span>
							</li>
							<li v-if="orderDetail.promotion_money > 0">
								<label>优惠：</label>
								<span>￥{{ orderDetail.promotion_money }}</span>
							</li>
							<li v-if="orderDetail.coupon_money > 0">
								<label>优惠券金额：</label>
								<span>￥{{ orderDetail.coupon_money }}</span>
							</li>
							<li v-if="orderDetail.platform_coupon_total_money > 0">
								<label>平台优惠券金额：</label>
								<span>￥{{ orderDetail.platform_coupon_total_money }}</span>
							</li>
							<li>保证金：￥{{ orderDetail.ensure_money }}</li>
							<li>总计：订单共{{orderDetail.goods_num}}件商品，总计：￥{{orderDetail.order_money}}</li>
						</ul>
					</div>
					<!-- 订单总计 -->
					<!-- <ul class="total">
						<li>
							<label>商品金额：</label>
							<span>￥{{ orderDetail.goods_money }}</span>
						</li>
						<li>
							<label>运费：</label>
							<span>￥{{ orderDetail.delivery_money }}</span>
						</li>
						<li v-if="orderDetail.invoice_money > 0">
							<label>税费：</label>
							<span>￥{{ orderDetail.invoice_money }}</span>
						</li>
						<li v-if="orderDetail.adjust_money != 0">
							<label>订单调整：</label>
							<span>
								<template v-if="orderDetail.adjust_money < 0">
									-
								</template>
								<template v-else>
									+
								</template>
								￥{{ orderDetail.adjust_money | abs }}
							</span>
						</li>
						<li v-if="orderDetail.promotion_money > 0">
							<label>优惠：</label>
							<span>￥{{ orderDetail.promotion_money }}</span>
						</li>
						<li v-if="orderDetail.coupon_money > 0">
							<label>优惠券金额：</label>
							<span>￥{{ orderDetail.coupon_money }}</span>
						</li>
						<li v-if="orderDetail.platform_coupon_total_money > 0">
							<label>平台优惠券金额：</label>
							<span>￥{{ orderDetail.platform_coupon_total_money }}</span>
						</li>
						<li v-if="orderDetail.balance_money > 0">
							<label>使用余额：</label>
							<span>￥{{ orderDetail.balance_money }}</span>
						</li>
						<li class="pay-money">
							<label>实付款：</label>
							<span>￥{{ orderDetail.pay_money }}</span>
						</li>
					</ul> -->
				</template>
			</div>
		</el-card>
	</div>
</template>

<script>
	import { memberOrderDetail } from '@/api/bulkOrder';
	import orderMethod from '@/utils/orderMethod';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'order_detail',
		components: {},
		mixins: [orderMethod],
		data: () => {
			return {
				orderId: 0,
				orderDetail: null,
				loading: true,
				yes: true,
				show_package:0,
			};
		},
		created() {
			this.orderId = this.$route.query.order_id;
			this.getOrderDetail();
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		methods: {
			calcGoodsPercent(num,total){
				if(total){
					return Number(((num/total)*100).toFixed(2));
				}else{
					return Number(0);
				}
			},
			calcPercent(mode){
				if(mode=='pay'){
					var paied = 0;
					this.orderDetail.pay_plan.forEach(item=>{
						if(item.pay_status != 0 ){
							paied++;
						}
					})
					if(this.orderDetail.pay_plan.length){
						return Number(((paied/this.orderDetail.pay_plan.length)*100).toFixed(2));
					}else{
						return Number(0);
					}
				}else{
					var shipping = 0;
					this.orderDetail.shipping_plan.plam_list.forEach(item=>{
						if(item.order_status != 0 ){
							shipping++;
						}
					})
					if(this.orderDetail.shipping_plan.plam_list.length){
						return Number(((shipping/this.orderDetail.shipping_plan.plam_list.length)*100).toFixed(2));
					}else{
						return Number(0);
					}
				}
				
			},
			getOrderDetail() {
				memberOrderDetail({
						order_id: this.orderId
					})
					.then(res => {
						if (res.code >= 0) {
							console.log(res, 'apiOrderDetail')
							this.orderDetail = res.data;
							this.loading = false;
						} else {
							this.$message({
								message: '未获取到订单信息',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/bulk_order'
									});
								}
							});
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/bulk_order'
								});
							}
						});
					});
			},
			operation(action) {
				switch (action) {
					case 'orderPay': // 支付
						this.orderPay(this.orderDetail);
						break;
					case 'orderClose': //关闭
						this.orderClose(this.orderDetail.order_id, () => {
							this.getOrderDetail();
						});
						break;
					case 'memberTakeDelivery': //收货
						this.orderDelivery(this.orderDetail.order_id, () => {
							this.getOrderDetail();
						});
						break;
					case 'trace': //查看物流
						this.$router.push({
							path: '/member/logistics',
							query: {
								order_id: this.orderDetail.order_id
							}
						});
						break;
					case 'memberOrderEvaluation': //评价
						this.$router.pushToTab({
							path: '/evaluate',
							query: {
								order_id: this.orderDetail.order_id
							}
						});
						break;
				}
			},
			imageError(index) {
				this.orderDetail.order_goods[index].sku_image = this.defaultGoodsImage;
			}
		},
		filters: {
			abs(value) {
				return Math.abs(parseFloat(value)).toFixed(2);
			}
		}
	};
</script>
<style lang="scss" scoped>
	.el-table{
		margin-bottom: 10px;
	}
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.order-detail {
		.order-status {
			background-color: #fff;
			margin-bottom: 20px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			.go-pay {
				p {
					display: inline-block;
					vertical-align: middle;

					span {
						font-weight: bold;
						color: $base-color;
						font-size: 18px;
					}
				}
			}

			.operation {
				margin-top: 10px;
			}
		}
		.refund-box{
			display:flex;
		}
		.order-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;
					&:last-child {
						flex: initial;
					}
				}
			}
		}
		
		.take-delivery-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
			.el-timeline{
				display: block;
				li{
					margin: 0 !important;
				}
			}
		}
		.pay_info{
			h4{
				display: flex;
				align-items: center;
				.el-progress{
					width: 23%;
					margin-left: 20px;
					display: flex;
					align-items: center;
					.el-progress__text{
						margin-left: 10px;
					}
				}
			}
			h5{
				font-weight: normal;
				margin-bottom: 10px;
				font-size: 14px;
			}
			.payment_voucher{
				width: 60px;
				height: 60px;
				display: block;
			}
			.el-progress{
				display: flex;
				align-items: center;
				.el-progress__text{
					margin-left: 10px;
					white-space: nowrap;
				}
			}
			.goods_item{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				img{
					width: 60px;
					height: 60px;
					display: block;
					margin-right: 10px;
				}
				.goods_info{
					display: flex;
					flex-direction: column;
					flex: 1;
					.name{
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
			.package_names{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				.name{
					font-size: 14px;
					line-height: 18px;
					padding: 6px 10px;
					background-color: #fff;
					border: 1px solid #c6c6c6;
					border-radius: 5px;
					margin-right: 15px;
					cursor: pointer;
					&.active{
						color: #fff;
						background-color: #00a98f;
						border-color: #00a98f;
					}
				}
			}
			.package_info{
				display: flex;
				align-items: flex-start;
				.left_info{
					width: 35%;
					margin-right: 30px;
					.item{
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 18px;
						.tit{
							min-width: 75px;
						}
					}
					.goods_info{
						margin-bottom: 10px;
						display: flex;
						align-items: center;
						img{
							width: 60px;
							height: 60px;
							display: block;
							margin-right: 10px;
						}
						.info{
							.name{
								font-size: 14px;
								line-height: 18px;
								margin-bottom: 8px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}
							.num{
								line-height: 16px;
							}
						}
					}
				}
				.right_info{
					flex: 1;
					max-height: 500px;
					overflow-y: auto;
					padding-left: 10px;
					.package_status{
						margin-bottom: 10px;
					}
				}
			}
		}
		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			border-bottom: 1px solid #eeeeee;
			li {
				float: left;

				&:nth-child(1) {
					width: 50%;

					&.no-operation {
						width: 60%;
					}
				}

				&:nth-child(2) {
					width: 15%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 15%;
				}

				&:nth-child(5) {
					width: 10%;
				}
			}
		}

		.list {
			border-bottom: 1px solid #eeeeee;

			.item {
				background-color: #fff;
				padding: 10px 0;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 50%;
						line-height: inherit;

						&.no-operation {
							width: 60%;
						}

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 15%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 10%;
					}
				}
			}
		}

		// 总计
		.total {
			padding: 20px;
			background-color: #fff;
			text-align: right;

			li {
				span {
					width: 150px;
					display: inline-block;
				}

				&.pay-money {
					font-weight: bold;

					span {
						color: $base-color;
						font-size: 16px;
						vertical-align: middle;
					}
				}
			}
		}
	}
	.red-text{
		color: red;
	}
</style>
